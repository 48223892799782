<!--数据-->
<template>
  <div class="container data-box">
    <div class="row">
      <div class="col-md-12">
        <h1 class="text-capitalize">技术评价数据</h1>
      </div>
    </div>
    <el-row>
      <b-card class="col-ms-12 filter" bg-variant="light">
        <b-form-group label="固废来源" label-cols-sm="4" label-align-sm="right">
          <el-select v-model="searchModel.sources" multiple collapse-tags
                     placeholder="请选择" @change="queryWasteBigSelect">
            <el-option v-for="item in options.wasteSource" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </b-form-group>
        <b-form-group label="固废大类" label-cols-sm="4" label-align-sm="right">
          <el-select v-model="searchModel.wasteBigTypes" multiple collapse-tags
                     placeholder="请选择" @change="queryWasteSmallSelect">
            <el-option v-for="item in options.wasteBigType" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </b-form-group>
        <b-form-group label="固废小类" label-cols-sm="4" label-align-sm="right">
          <el-select v-model="searchModel.wasteSmallTypes" multiple collapse-tags
                     placeholder="请选择">
            <el-option v-for="item in options.wasteSmallType" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </b-form-group>
        <b-form-group label="区域" label-cols-sm="4" label-align-sm="right">
          <el-cascader :options="options.area" :props="{ multiple: true, checkStrictly: true }" clearable
                       collapse-tags></el-cascader>
        </b-form-group>
        <b-form-group label="年份" label-cols-sm="4" label-align-sm="right">
          <el-select v-model="searchModel.years" multiple collapse-tags
                     placeholder="请选择">
            <el-option v-for="item in options.years" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </b-form-group>
        <b-form-group label="模糊查询" label-cols-sm="4" label-align-sm="right">
          <el-input placeholder="请输入内容" v-model="searchModel.searchValue" clearable>
          </el-input>
        </b-form-group>
        <div class="search el-col-md-24">
          <el-button type="primary" @click="selectData">查 询</el-button>
        </div>
      </b-card>

      <b-card class="col-ms-12 search-data" header="查询结果">
        <el-table :data="table.tableData" style="width: 100%;overflow: auto" max-height="500">
          <el-table-column prop="techName" label="技术名称" width="250">
          </el-table-column>
          <el-table-column prop="fillingTime" label="填报时间" width="160">
          </el-table-column>
          <!--          <el-table-column fixed prop="areaName" label="区域" width="150">-->
          <!--          </el-table-column>-->
          <el-table-column prop="wasteName" label="固废类型" width="120">
          </el-table-column>
          <el-table-column prop="personLiable" label="提交人" width="100">
          </el-table-column>
          <el-table-column prop="fillingUnit" label="提交单位" width="220">
          </el-table-column>
          <el-table-column prop="unitAddress" label="单位地址" width="220">
          </el-table-column>
          <el-table-column prop="indTotal" label="综合影响" width="150">
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="150">
            <template slot-scope="scope">
              <el-button @click.native.prevent="viewDetail(scope.row.id)" type="text" size="small">
                详情
              </el-button>
              <el-button @click.native.prevent="viewChart(scope.row.id)" type="text" size="small">
                雷达图
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination background layout="total, prev, pager, next" :total="table.total" :page-count="table.pageCount"
                       :page-size="table.pageSizes" :current-page="table.currentPage"
                       @current-change="handleCurrentChange">
        </el-pagination>
      </b-card>
    </el-row>

    <!--    <el-card class="form" shadow="always"
                 style="height: 400px"
                 :style="{display: (view.chartVisible === true ? 'block' : 'none')}">
          <h3>评价结果</h3>
          <div ref="charts" class="echarts-content"></div>
        </el-card>-->

    <el-dialog title="详情" :visible.sync="view.detailVisible" width="60%" class="detailDialog">
      <el-row :gutter="20">
        <el-col span="6">
          技术名称: {{ this.dialogData.techName }}
        </el-col>
        <el-col span="6">
          信息采集负责人: {{ this.dialogData.personLiable }}
        </el-col>
        <el-col span="6">
          联系方式: {{ this.dialogData.applicationUnit }}
        </el-col>
        <el-col span="6">
          填报单位: {{ this.dialogData.fillingUnit }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col span="6">
          技术应用单位地址: {{ this.dialogData.unitAddress }}
        </el-col>
        <el-col span="6">
          填报时间: {{ this.dialogData.fillingTime }}
        </el-col>
        <el-col span="6">
          项目投产时间: {{ this.dialogData.productionTime }}
        </el-col>
        <el-col span="6">
          数据来源: {{ this.dialogData.source }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col span="6">
          固废大类: {{ this.dialogData.wasteType }}
        </el-col>
        <el-col span="6">
          固废小类: {{ this.dialogData.wasteSubtype }}
        </el-col>
        <el-col span="6">
          资源化产品大类: {{ this.dialogData.resourceType }}
        </el-col>
        <el-col span="6">
          资源化产品小类: {{ this.dialogData.resourceSubtype }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col span="6">
          技术就绪度自评: {{ this.dialogData.techReadiness }}
        </el-col>
        <el-col span="6">
          年固废处理规模(吨): {{ this.dialogData.treatmentScale }}
        </el-col>
        <el-col span="6">
          年处理固废总量(吨): {{ this.dialogData.treatmentTotal }}
        </el-col>
        <el-col span="6">
          新鲜水（自来水）消耗量(立方米/年): {{ this.dialogData.tapWasterConsumption }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col span="6">
          项目占地面积(平方米): {{ this.dialogData.areaCovered }}
        </el-col>
        <el-col span="6">
          电消耗量(千瓦时/年): {{ this.dialogData.electricityConsumption }}
        </el-col>
        <el-col span="6">
          原煤消耗量(吨/年): {{ this.dialogData.coalConsumption }}
        </el-col>
        <el-col span="6">
          天然气消耗量(m3): {{ this.dialogData.naturalGasConsumption }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col span="6">
          蒸汽消耗量(立方米/年): {{ this.dialogData.steamConsumption }}
        </el-col>
        <el-col span="6">
          柴油消耗量(吨/年): {{ this.dialogData.dieselConsumption }}
        </el-col>
        <el-col span="6">
          汽油消耗(吨/年): {{ this.dialogData.oilConsumption }}
        </el-col>
        <el-col span="6">
          进入到资源化生产过程中的固废量(吨/年): {{ this.dialogData.resourceWaste }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col span="6">
          甲烷产生量(立方米/年): {{ this.dialogData.copperProduction }}
        </el-col>
        <el-col span="6">
          循环水利用量(吨): {{ this.dialogData.circulatingWaterConsumption }}
        </el-col>
        <el-col span="6">
          余热利用量(W): {{ this.dialogData.wasteHeatRecovery }}
        </el-col>
        <el-col span="6">
          废气年排放总量(立方米/年): {{ this.dialogData.gasTotal }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col span="6">
          二氧化硫年排放总量(吨/年): {{ this.dialogData.so2Total }}
        </el-col>
        <el-col span="6">
          氮氧化物年排放总量(吨/年): {{ this.dialogData.noxTotal }}
        </el-col>
        <el-col span="6">
          废水年排放总量(立方米/年): {{ this.dialogData.sewageTotal }}
        </el-col>
        <el-col span="6">
          化学需氧量（COD)年排放总量(吨/年): {{ this.dialogData.codTotal }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col span="6">
          化学需氧量（BOD)年排放总量(吨/年): {{ this.dialogData.bodTotal }}
        </el-col>
        <el-col span="6">
          氨氮（NH3-N)年排放总量(吨/年): {{ this.dialogData.nh3nTotal }}
        </el-col>
        <el-col span="6">
          总磷（P)年排放总量(吨/年): {{ this.dialogData.phosphorusTotal }}
        </el-col>
        <el-col span="6">
          固废（废渣）年排放总量(吨/年): {{ this.dialogData.wasteDischarge }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col span="6">
          总投资建设费用(万元): {{ this.dialogData.constructionCost }}
        </el-col>
        <el-col span="6">
          年运行成本(万元/年): {{ this.dialogData.operatingCost }}
        </el-col>
        <el-col span="6">
          销售收入(万元/年): {{ this.dialogData.salesRevenue }}
        </el-col>
        <el-col span="6">
          净利润(万元/年): {{ this.dialogData.netProfit }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col span="6">
          净现值(万元): {{ this.dialogData.netPresentValue }}
        </el-col>
        <el-col span="6">
          专利价值(万元): {{ this.dialogData.patentValue }}
        </el-col>
        <el-col span="6">
          提供就业岗位数(个): {{ this.dialogData.offerJobTotal }}
        </el-col>
        <el-col span="6">
          循环经济示范性(是/否): {{ this.dialogData.circularEconomy |formatBool}}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col span="6">
          职工可能暴露在腐蚀/有毒化学品环境下: {{ this.dialogData.inToxicCorrosion |formatBool}}
        </el-col>
        <el-col span="6">
          职工可能暴露在泼溅条件下: {{ this.dialogData.inHighSpeed |formatBool}}
        </el-col>
        <el-col span="6">
          职工可能暴露在高温表面下: {{ this.dialogData.inHighTemperature |formatBool}}
        </el-col>
        <el-col span="6">
          职工可能暴露在高速部件下: {{ this.dialogData.inSplash |formatBool}}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col span="6">
          职工可能暴露在高压电下: {{ this.dialogData.inHighVoltage |formatBool}}
        </el-col>
        <el-col span="6">
          职工可能暴露在高分贝噪声下: {{ this.dialogData.inHighDecibel |formatBool}}
        </el-col>
        <el-col span="6">
          职工可能暴露在难闻气味下: {{ this.dialogData.inOdorousGas | formatBool}}
        </el-col>
        <el-col span="6">
          职工可能暴露在充满灰尘工作条件下: {{ this.dialogData.inDust | formatBool}}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col span="6">
          公众对技术实施的态度: {{ this.dialogData.publicRecognition | formatBool}}
        </el-col>
        <el-col span="6">
          职工平均工资(万元/年): {{ this.dialogData.avgWage }}
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
                <!--        <el-button @click="view.dialogVisible = false">关 闭</el-button>-->
            </span>
    </el-dialog>

    <el-dialog title="技术雷达图" :visible.sync="view.chartVisible" width="60%" @opened="initChart">
      <div ref="charts" class="echarts-content"></div>
    </el-dialog>
  </div>
</template>
<script>
import ui from "../../libs/ui"
import {
  getWasteSourceSelect,
  getWasteBigTypeSelect,
  getWasteSmallTypeSelect,
  getAreaSelect,
  getYearSelect
} from "@/api/common"
import {
  queryEvaluateTable,
  queryTechDetail,
  queryTechChart
} from "@/api/waste"

export default {
  name: 'Data',
  props: {
    msg: String
  },
  data() {
    return {
      view: {
        detailVisible: false,
        chartVisible: false,
        charts: {}
      },
      dialogData: {},
      searchModel: {
        searchValue: '',
        sources: [],
        wasteBigTypes: [],
        wasteSmallTypes: [],
        years: []
      },
      options: {
        wasteSource: [],
        wasteBigType: [],
        wasteSmallType: [],
        area: [],
        years: [],
      },
      mapOptions: {
        title: {
          text: '技术评价雷达图'
        },
        tooltip: {},
        legend: {
          data: ['技术']
        },
        radar: {
          // shape: 'circle',
          name: {
            textStyle: {
              color: '#fff',
              backgroundColor: '#999',
              borderRadius: 3,
              padding: [3, 5]
            }
          },
          indicator: [
            {name: '资源影响（R）', max: 50},
            {name: '环境影响（En）', max: 50},
            {name: '经济影响（Ec）', max: 50},
            {name: '社会影响（S）', max: 50},
          ]
        },
        series: [{
          name: '技术',
          type: 'radar',
          // areaStyle: {normal: {}},
          data: [
            {
              label: {
                show: true,
                formatter: function(params) {
                  return params.value;
                }
              },
              value: [90, 89, 95, 100],
              name: '11'
            }
          ]
        }]
      },
      table: {
        tableData: [],
        total: 0,
        currentPage: 1,
        pageCount: 0,
        pageSizes: 20
      }
    }
  },
  mounted() {
    this.init()
  },
  filters: {
    formatBool(val) {
      if(val === 0){
        return '是'
      }else{
        return '否'
      }
    }
  },
  methods: {
    init() {
      this.queryWasteSourceSelect()
      this.queryAreaSelect()
      this.queryYearSelect()
      this.selectData()
    },
    initChart() {
      // debugger
      this.view.charts = this.$echarts.init(this.$refs.charts);
      this.view.charts.setOption(this.mapOptions);
    },
    queryAreaSelect() {
      getAreaSelect().then(data => {
        this.options.area = data
      })
    },
    queryYearSelect() {
      getYearSelect().then(data => {
        this.options.years = data
      })
    },
    queryWasteSourceSelect() {
      getWasteSourceSelect().then(data => {
        this.options.wasteSource = data
      })
    },
    queryWasteBigSelect() {
      // debugger
      if (this.searchModel.sources.length === 0) return
      getWasteBigTypeSelect(this.searchModel.sources).then(data => {
        this.options.wasteBigType = data
      })
    },
    queryWasteSmallSelect() {
      if (this.searchModel.wasteBigTypes.length === 0) return
      getWasteSmallTypeSelect(this.searchModel.wasteBigTypes).then(data => {
        this.options.wasteSmallType = data
      })
    },
    queryTableData(searchParams, page) {
      if (!searchParams) {
        searchParams = this.searchModel
      }
      queryEvaluateTable(searchParams, page).then(data => {
        this.table.tableData = data.records
        this.table.total = data.total
        this.table.currentPage = data.current
        this.table.pageSizes = data.size
        this.table.pageCount = data.pages
      })
    },
    selectData() {
      this.queryTableData()
    },
    handleCurrentChange(val) {
      const page = {
        pageNo: val,
        pageSize: this.table.pageSizes
      }
      this.queryTableData(this.searchModel, page)
    },
    viewDetail(id) {
      // debugger
      if (id === null || id === undefined || id === '') {
        return
      }
      queryTechDetail(id).then(data => {
        this.dialogData = data
        this.view.detailVisible = true
      })
    },
    viewChart(id) {
      // debugger
      if (id === null || id === undefined || id === '') {
        return
      }
      let self = this
      self.view.chartVisible = true
      queryTechChart(id).then(data => {
        self.mapOptions.legend.data[0] = data.name
        self.mapOptions.series[0].name = data.name
        self.mapOptions.series[0].data[0].name = data.name
        self.mapOptions.series[0].data[0].value = data.data
        self.view.charts.setOption(self.mapOptions);

      })
    },
    toSearch() {
      // eslint-disable-next-line no-debugger
      // debugger
      if (this.keyword === '') {
        ui.toast(this, ui.variant.primary, '请输入搜索内容')
        return;
      }
      if (this.$store.getters.token === '') {
        ui.toast(this, ui.variant.primary, '请先登录账号')
        return;
      }
      this.$router.push('/search/' + this.keyword)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
@import "../../assets/less/data.less";

.echarts-content {
  height: 600px;
  width: 800px;
  margin-left: 8%;
}
</style>
