import http from "@/libs/http";

export function queryFeatureTableData(searchParams, page) {
    return new Promise((resolve) => {
        http.post('wasteFeature/getPage', searchParams, {params: page}).then(data => {
            resolve(data)
        })
    })
}

export function compare(searchParams) {
    return new Promise((resolve) => {
        http.post('wasteFeature/compare', searchParams).then(data => {
            resolve(data)
        })
    })
}

export function queryFeatureDetail(id) {
    return new Promise((resolve) => {
        http.get('wasteFeature/getInfo', {params: {id: id}}).then(data => {
            resolve(data)
        })
    })
}

export function queryEvaluateTable(searchParams, page) {
    return new Promise((resolve) => {
        http.post('techData/getPage', searchParams, {params: page}).then(data => {
            resolve(data)
        })
    })
}

export function queryTechChart(id) {
    return new Promise((resolve) => {
        http.get('techIndicators/getTechChart', {params: {id: id}}).then(data => {
            resolve(data)
        })
    })
}

export function queryTechDetail(id) {
    return new Promise((resolve) => {
        http.get('techData/getDetail', {params: {id: id}}).then(data => {
            resolve(data)
        })
    })
}

export function queryCityWasteTableData(searchParams, page) {
    return new Promise((resolve) => {
        http.post('city/waste/getPage', searchParams, {params: page}).then(data => {
            resolve(data)
        })
    })
}

export function queryCityWasteYear(id) {
    return new Promise((resolve) => {
        http.get('city/waste/getYearSelect', {params: {id: id}}).then(data => {
            resolve(data)
        })
    })
}

export function queryCityWasteDetail(id) {
    return new Promise((resolve) => {
        http.get('city/waste/getInfo', {params: {id: id}}).then(data => {
            resolve(data)
        })
    })
}

export function queryCityMineTableData(searchParams, page) {
    return new Promise((resolve) => {
        http.post('city/mine/getPage', searchParams, {params: page}).then(data => {
            resolve(data)
        })
    })
}

export function queryCityMineYear(id) {
    return new Promise((resolve) => {
        http.get('city/mine/getYearSelect', {params: {id: id}}).then(data => {
            resolve(data)
        })
    })
}

export function queryCityMineDetail(id) {
    return new Promise((resolve) => {
        http.get('city/mine/getInfo', {params: {id: id}}).then(data => {
            resolve(data)
        })
    })
}